<template>
  <div class="countdown">
    <div class="time-item" :class="{ mobile: mobile }">
      <p>00h :</p>
      <p>{{ minutes }}m :</p>
      <p>{{ seconds }}s</p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      actualTime: moment().format("X"),
      minutes: 0,
      seconds: 0,
    };
  },
  props: {
    tempo_escassez: {},
    mobile: {
      type: Boolean,
      default: false,
    },
    product_id: {
      type: Number,
      default: -1,
    },
  },
  watch: {
    actualTime() {
      this.compute();
    },
  },

  methods: {
    updateTime() {
      this.actualTime = moment().format("X");
      setTimeout(() => {
        this.updateTime();
      }, 1000);
    },
    getDiffInSeconds() {
      return (
        moment(localStorage.getItem(`time_scarcity-${this.product_id}`)).format(
          "X"
        ) - this.actualTime
      );
    },
    compute() {
      const duration = moment.duration(this.getDiffInSeconds(), "seconds");
      this.minutes =
        duration.minutes() > 0
          ? duration.minutes() < 10
            ? `0${duration.minutes()}`
            : duration.minutes()
          : "00";
      this.seconds =
        duration.seconds() > 0
          ? duration.seconds() < 10
            ? `0${duration.seconds()}`
            : duration.seconds()
          : "00";
    },
  },
  created() {
    if (!localStorage.getItem(`time_scarcity-${this.product_id}`)) {
      localStorage.setItem(
        `time_scarcity-${this.product_id}`,
        moment()
          .add(+this.tempo_escassez, "minutes")
          .format()
      );
    }
    this.compute();
    this.updateTime();
  },
};
</script>

<style scoped>
.time-item {
  background: rgba(255, 255, 255, 0.2);
  padding: 10px 20px;
  border-radius: 5px;
  /* min-width: 200px; */
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}
.time-item p {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  display: block;
  color: #fff;
  width: 60px;
}
.time-item p:last-child {
  width: 50px;
}
.time-item.mobile p {
  font-size: 14px;
}
.time-item.mobile {
  padding: 5px 7px;
  margin-top: 10px;
  width: 55%;
}
</style>