import Api from '@/services/API';

export default class GatewayService extends Api {

    static base = process.env.VUE_APP_GREENN_GATEWAY_URL; // Definindo o novo endpoint

    static resource = '/checkout/card';

    constructor(resource, options = {}, http = null) {
        super(GatewayService.normalize(GatewayService.base, resource), options, http);
    }

    static build() {
        return new this(this.resource);
    }

    card(data) {         
        return this.post('', data);
    }
}
