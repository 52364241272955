<template>
  <div
    class="escassez"
    :class="{ mobile: mobile }"
    :style="`background: ${dados.scarcity_background_color}`"
  >
    <div class="group">
      <div class="left">
        <img src="@/assets/img/icon/escassez.svg" v-if="!mobile" />
        <div>
          <h5>{{ dados.scarcity_title }}</h5>
          <p>{{ dados.scarcity_subtitle }}</p>
        </div>
      </div>
      <CountDownEscassez
        :tempo_escassez="+dados.scarcity_time"
        :mobile="mobile"
        :product_id="product_id"
      />
    </div>
  </div>
</template>

<script>
import CountDownEscassez from "./CountDownEscassez.vue";
export default {
  components: {
    CountDownEscassez,
  },
  props: {
    dados: {
      type: Object,
      default: () => {},
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    product_id: {
      type: Number,
      default: -1,
    },
  },
};
</script>

<style scoped>
.escassez {
  background: #00af7b;
  /* height: 80px; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.group {
  padding: 20px 70px;
  padding-right: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.escassez.mobile .group {
  padding: 15px;
  display: block;
}
.left {
  display: flex;
  align-items: center;
  gap: 30px;
}
h5,
p {
  margin: 0;
  color: #fff;
}
h5 {
  font-weight: 600;
  font-size: 20px;
}
p {
  font-size: 14px;
  margin-top: 3px;
}
.escassez.mobile h5 {
  font-size: 14px;
}
.escassez.mobile p {
  font-size: 12px;
}
</style>