<template>
  <div>
    <div v-bind:class="{ bannerImage: trasparent }" class="mb-5">
      <div
        v-if="trasparent"
        class="imageProduct"
        :style="{ backgroundImage: `url(${product.images[0].path})` }"
      ></div>
      <div class="is-stick">
        <Escassez
          :mobile="mobile"
          v-if="escassez"
          :dados="dados_escassez"
          :product_id="product_id"
        />
        <b-navbar
          v-if="!escassez"
          type="dark"
          variant="light"
          class="d-flex justify-content-between align-items-center"
          v-bind:class="{ trasparent: trasparent }"
        >
          <b-navbar-brand href="#" disabled v-if="logo">
            <img class="logo" :src="logo" />
          </b-navbar-brand>
          <b-navbar-brand
            href="https://greenn.com.br/"
            target="_blank"
            v-if="!logo"
          >
            <img v-if="!isHeaven" class="logo" src="@/assets/img/logo2.png" />
            <img v-else class="logo" src="@/assets/img/heaven/logo.svg" />
          </b-navbar-brand>

          <!-- <div class="d-flex">
        <a href="#">Voltar</a>
        <p class="etapas"><span>01</span> / 03</p>
      </div> -->
        </b-navbar>
      </div>

      <div v-if="trasparent" class="imageProductEspace"></div>
    </div>
  </div>
</template>

<script>
import Escassez from "@/components/Checkout/Escassez";

export default {
  data() {
    return {
      text: "",
      product: [],
      trasparent: "",
    };
  },
  components: {
    Escassez,
  },
  props: {
    logo: {
      type: String,
      default: "",
    },
    escassez: {
      type: Boolean,
      default: false,
    },
    dados_escassez: {
      type: Object,
      default: () => {},
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    product_id: {
      type: Number,
      default: -1,
    },
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    getBanner() {
      if (this.product.banner === true) {
        this.trasparent = true;
      } else {
        this.trasparent = false;
      }
    },
  },
  mounted() {
    this.$root.$on("getproduct", (data) => {
      this.product = data;
      this.getBanner();
    });
  },
};
</script>
<style lang="scss" scoped>
.logo {
  max-width: 100px;
  max-height: 33px;
}

.imageProduct {
  width: 100%;
  height: 400px;
  position: absolute;
  background-repeat: round;
  top: 0px;
}

.imageProductEspace {
  margin-bottom: 65vh;
}

nav.trasparent {
  background: #0000 !important;
  border: none !important;
  position: absolute !important;
}

p.trasparentText {
  color: #fff !important;
}

nav.navbar.navbar-dark.bg-light.navbar-expand {
  background: var(--bg-checkout) !important;
  // border-bottom: 1px solid var(--border-color);
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
}

.title {
  display: inline-block;
  margin-left: 10px;
  color: var(--main-color);
  font-size: 20px;
  font-weight: bold;
}

.is-stick {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
}
</style>